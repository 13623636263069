<script lang="ts" setup>
import { Colours } from "~/lib/company-profile/colours";
import { isCompanyKey, isProteinDiversification, isWorkingConditions } from "~/lib/company-profile/type-guards";
import type { TypeDataSourceParsed } from "~/types/TypeDataSourceParsed";
import type { TypeWcCompany } from "~/types/csv/TypeWc";

const props = defineProps<{
  kpi: string;
  companyIndex: number;
  dataSourceParsed: TypeDataSourceParsed;
  projectSlug: string;
  value?: number;
}>();

const colours = new Colours(props.projectSlug);

const colorByScore = (score: number, kpi: string) => {
  const methodologyForKpi = props.dataSourceParsed.methodology?.find((m) =>
    m.Code?.includes(kpi),
  );

  if (methodologyForKpi && "Score" in methodologyForKpi) {
    return colours.riskColour(score, Number(methodologyForKpi.Score));
  }
};

const kpiColor = (kpi: string, company: TypeDataSourceParsed["company"]) => {
  if (company && isCompanyKey(kpi, company)) {
    if (company[kpi] === "N/A") return "#d8d8d8";
    return colorByScore(Number(company[kpi]), kpi);
  }
};

// This needs to be replaced with a proper server-side data source request
const pdGrading = [{
  text: "DNF",
  colour: "#A80605",
  min: null,
  max: 13,
}, {
  text: "Low",
  colour: "#FF3736",
  min: 13,
  max: 38,
}, {
  text: "Partial",
  colour: "#FF962A",
  min: 38,
  max: 63,
}, {
  text: "Good",
  colour: "#FFBA00",
  min: 63,
  max: 89,
}, {
  text: "Lead",
  colour: "#63C954",
  min: 89,
  max: 100,
}];

const pdGrade = computed(() => [...pdGrading].reverse().reduce((acc, grade) => Number(props.value) <= grade.max ? grade : acc));
</script>

<template>
  <td>
    <div
      v-if="isWorkingConditions(dataSourceParsed) && isCompanyKey(kpi + ' Change', dataSourceParsed.data?.[companyIndex])"
      class="flex items-center justify-center rounded py-2 text-center text-sm font-semibold uppercase text-white max-lg:h-full"
      :style="{
        backgroundColor: isCompanyKey(kpi, dataSourceParsed.data?.[companyIndex])
          ? kpiColor(kpi, dataSourceParsed.data?.[companyIndex])
          : colorByScore(value || 0, kpi),
      }"
    >
      <span v-if="isCompanyKey(kpi, dataSourceParsed.data?.[companyIndex]) && dataSourceParsed.data?.[companyIndex]?.[kpi] === 'N/A'">N/A</span>
      <span
        v-else-if="dataSourceParsed.data?.[companyIndex]?.[(kpi + ' Change') as keyof TypeWcCompany] === 'N/A'"
      >&nbsp;</span>
      <NuxtIcon
        v-else
        :name="`fairr:disclosure-${dataSourceParsed.data?.[companyIndex]?.[(kpi + ' Change') as keyof TypeWcCompany]?.toLowerCase() || 'stationary'}`"
        size="16"
      />
    </div>
    <div
      v-else-if="isProteinDiversification(dataSourceParsed)"
      class="flex min-w-[70px] items-center justify-center rounded py-2 text-center text-sm font-semibold uppercase text-white max-lg:h-full"
      :style="{
        backgroundColor: pdGrade.colour,
      }"
    >
      <span>{{ pdGrade.text }}</span>
    </div>
  </td>
</template>

<style lang="scss" scoped>
td {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 5.2%;

  &:deep(svg g) {
    stroke-width: 0;
  }

  &:deep(svg path) {
    fill: white;
  }
}
</style>
